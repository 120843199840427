<template>
  <div class="page-wrapper">
    
    <section class="hero">
      <div class="data">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Trees of Lives">
        <h1 class="title">Offset your carbon footprint with high-quality carbon credits</h1>
        <img src="@/assets/img/arrow-down.svg" class="arrow" alt="Arrow down">
      </div>
      <div class="img-wrap">
        <img src="@/assets/img/hero-mobile.jpg" alt="Trees of Lives">
      </div>
    </section>

    <a href="http://eepurl.com/hLHUwj" target="_blank" class="cta-wrap">
      <p class="title small">Subscribe to our newsletter</p>
      <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
    </a>

    <section class="bloc-split">
      <div class="data darker">
        <h2 class="title">Meet Martin</h2>
        <a href="https://www.youtube.com/watch?v=4LNdy8wSirM" class="video glightbox">
          <img src="@/assets/img/play.svg" class="play" alt="Arrow right">
          <img src="@/assets/img/martin-video.jpg" alt="Video Martin Beaudoin Nadeau">
        </a>
        <div class="martin">
          <img src="@/assets/img/martin.png" alt="Martin Beaudoin Nadeau">
          <div class="info">
            <p class="title small">Martin Beaudoin Nadeau</p>
            <p class="regular-text">Vision of TreesOfLives<br>Founder and CEO</p>
          </div>
        </div>
      </div>
      <div class="data darker">
        <img src="@/assets/img/logo-viridis.png" class="logo-viridis" alt="Logo Viridis Terra">
        <h2 class="title">TreesOfLives is an initiative by Viridis Terra</h2>
        <ul>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">A team with more than 50 years of cumulative experience in ecosystem restoration</p>
          </li>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">A network of internationally renowned multidisciplinary experts</p>
          </li>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">Dozens of strategic partnerships with the civil, public and private sectors</p>
          </li>
        </ul>
      </div>
    </section>

    <a href="https://calendly.com/mbnadeau" target="_blank" class="cta-calendar">
      <img src="@/assets/img/icon-calendar.svg" class="calendar" alt="Calendar">
      <div class="data">
        <p class="title small">Let’s keep in touch.<br>Book a meeting</p>
        <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
      </div>
    </a>

    <section class="steps-wrap">
      <h2 class="title">How it works</h2>
      <div class="step">
        <img src="@/assets/img/1.svg" alt="1">
        <div class="data">
          <h3 class="title small">Support landowners</h3>
          <p class="regular-text">We offer the technology and expertise to support the landowner in establishing a model for the sustainable restoration and development of degraded land.</p>
        </div>
      </div>
      <div class="step">
        <img src="@/assets/img/2.svg" alt="2">
        <div class="data">
          <h3 class="title small">Help forests regrow</h3>
          <p class="regular-text">The forests grow and land produces, contributing to carbon sequestration. You benefit from carbon and biodiversity credits, and local communities gain a better quality of life.</p>
        </div>
      </div>
      <div class="step">
        <img src="@/assets/img/3.svg" alt="3">
        <div class="data">
          <h3 class="title small">Earn certified carbon credits</h3>
          <p class="regular-text">Profits from these sales are shared between the landowner and the investor, representing your return on investment. We then recreate this model with other landowners and on a larger scale.</p>
        </div>
      </div>
    </section>

    <a href="https://treesoflives.com/en/investment-for-the-future/" target="_blank" class="cta-wrap">
      <p class="title small">Support and invest with us</p>
      <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
    </a>

    <section class="bloc-split">
      <div class="img-wrap project">
        <img src="@/assets/img/project-mobile.jpg" alt="Project Muyuychi : restoring the Amazon with communities.">
      </div>
      <div class="data">
        <h2 class="title">Peru as a Starting Point : restoring the Amazon with local communities.</h2>
        <p class="regular-text">Our integrated approach and actions contribute to attaining each of the 17 Sustainable Development Goals of the United Nations. For the communities involved in our projects, this means a more prosperous future and dignified and fair living conditions.</p>
        <ul>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">Site preparation (In progress)</p>
          </li>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">Restoration of 100 hectares (Starts in fall 2021)</p>
          </li>
          <li>
            <img src="@/assets/img/puce.svg" alt="puce">
            <p class="regular-text">Restoration of 1,000 ha (After raising $6 million USD)</p>
          </li>
        </ul>
      </div>
    </section>

    <a href="http://eepurl.com/hLHUwj" target="_blank" class="cta-wrap">
      <p class="title small">Stay informed by subscribing to our newsletter</p>
      <img src="@/assets/img/arrow-right.svg" alt="Arrow right">
    </a>

    <footer>
      <p class="regular-text">© 2021, TreesOfLives Inc. All Rights Reserved.</p>
    </footer>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

export default {
  head() {
    return {
      title: {
        inner: 'Get Carbon offsetting and certified credits'
      },
      meta: [
        {
          name: 'description',
          content: 'TreesOfLives is an initiative by Viridis Terra',
          id: 'desc'
        },
        { name: 'application-name', content: 'Get Carbon offsetting and certified credits' },
        { name: 'twitter:title', content: 'TreesOfLives is an initiative by Viridis Terra'},
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: 'TreesOfLives is an initiative by Viridis Terra'
        },
        // Facebook / Open Graph
        { property: 'og:title', content: 'Get Carbon offsetting and certified credits'},
        { property: 'og:site_name', content: 'Get Carbon offsetting and certified credits' },
        {
          property: 'og:description',
          content: 'TreesOfLives is an initiative by Viridis Terra'
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://trees-of-lives.netlify.app/img/og.jpg'
        },
        {
          name: 'twitter:image',
          content: 'https://trees-of-lives.netlify.app/img/og.jpg'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle']),
  mounted () {
    this.lightboxelement = GLightbox
    GLightbox()
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';
@import '@/theme/button.scss';

  .hero {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .data {
      width: 100%;
      background-color: $color1;
      padding: 8vw;

      .logo {
        width: 250px;
        margin-bottom: 30vw;
      }

      .title {
        color: $color4;
      }

      .arrow {
        width: 25px;
        margin-top: 10px;
      }
    }

    .img-wrap {
      width: 100%;
    }

    @media screen and (min-width: 1000px) {
      .data {
        padding: 5vw;
        width: 50%;
        .logo {
          width: 250px;
          margin-bottom: 20vw;
        }
      }
      .img-wrap {
        width: 50%;
        background-image: url('../assets/img/hero-mobile.jpg');
        background-size: cover;
        img {
          display: none;
        }
      }
    }
  }

  .steps-wrap {
    width: 100%;
    background-color: $color4;
    display: flex;
    flex-wrap: wrap;
    padding: 10vw 8vw;
    .title {
      color: $color1;
    }

    .step {
      display: flex;
      justify-content: space-between;
      margin-top: 15vw;

      img {
        width: auto;
        height: 100px;
      }

      .data {
        width: calc(100% - 100px);
        padding-top: 30px;
        .title {
          margin-bottom: 10px;
        }
      }
    }

    @media screen and (min-width: 1000px) {
      padding: 5vw;
      justify-content: space-between;

      .title {
        width: 100%;
        margin-bottom: 5vw;
      }

      .step {
        margin-top: 0px;
        width: 31%;

        .data {
          .title {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .bloc-split {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .img-wrap {
      width: 100%;
    }

    .data {
      width: 100%;
      background-color: $color5;
      padding: 8vw;

      &.darker {
        background-color: $color1;

        .title {
          color: $color4;
        }

        .regular-text {
          color: $color4;
        }
      }

      .logo-viridis {
        width: 200px;
        margin-bottom: 20px;
      }

      .video {
        width: 100%;
        position: relative;
        display: block;

        .play {
          width: 40px;
          position: absolute;
          top: 50%;
          left: 25%;
          transform: translate(0,-50%);
        }
      }

      .martin {
        display: flex;
        align-items: center;
        margin-top: 20px;

        img {
          width: 100px;
          margin-right: 15px;
        }

        .info {
          .title {
            margin-bottom: 5px;
            font-size: 18px;
          }
        }
      }

      .title {
        margin-bottom: 30px;
      }

      ul {
        padding: 0;
        margin: 0;
        margin-top: 30px;
        li {
          list-style-type: none;
          padding: 0;
          margin: 0;
          margin-bottom: 15px;
          display: flex;
          align-items: flex-start;

          img {
            width: 25px;
            margin-right: 10px;
          }
        }
      }
    }

    @media screen and (min-width: 1000px) {
      .img-wrap {
        width: 50%;

        &.project {
          background-image: url('../assets/img/project-mobile.jpg');
          background-size: cover;
          background-position: center center;
          img {
            display: none;
          }
        }
      }
      .data {
        width: 50%;
      }
    }
  }

  footer {
    width: 100%;
    background-color: #fff;
    padding: 8vw;

    @media screen and (min-width: 1000px) {
      padding: 20px 5vw;
    }
  }

</style>
